.btn-with-status {
    padding-left: 60px;
    position: relative;

    .status {
        position: absolute;
        top: 10px;
        left: 15px;
    }
}

.status {
    display: inline-block;
    text-align: center;
    border-radius: 50%;

    background-repeat: no-repeat;
    background-position: center;

    &.trash {
        background-image: url(../src/img/trash.svg);
        background-size: 50%;
    }
}

.smallStatus {
    width: 30px;
    height: 30px;
}

.bigStatus {
    width: 50px;
    height: 50px;
}

.statusSuccess {
    border: 1px solid $success;
    background-size: 60%;
}

.statusDanger {
    border: 1px solid $error;
    background-size: 30%;
}

.statusValidation {
    border: 1px solid $validation;
    background-size: 45%;
}

.statusAdd {
    border: 1px solid $primary;
    background-size: 65%;
}

.statusSuccessPlain {
    background-color: $success;
    background-image: url(../src/img/success_white.svg);
}

.statusDangerPlain {
    background-color: $error;
    background-image: url(../src/img/exclamation.svg);
}

.statusSuccessInverse {
    background-image: url(../src/img/success_color.svg);
}

.statusValidationPlain {
    background-color: $validation;
    background-image: url(../src/img/validation_white.svg);
}

.statusValidationInverse {
    background-image: url(../src/img/validation_color.svg);
}

.statusAddPlain {
    background-color: $primary;
    background-image: url(../src/img/add_white.svg);
}

.statusAddInverse {
    background-image: url(../src/img/add_color.svg);
}

.statusSuccessNumber {
    background-image: none;
    color: white;
    font-size: 25pt;
    padding-top: 3px;
}
