input {
    border-radius: 10px;
    background-color: white;
    padding: 15px 20px;
    border: none;
    outline:none;

    .underline {
        text-decoration: underline;
    }
}

input + input {
    margin-top: 10px;
}

input:not([type=submit]):not([type=checkbox]){
    text-align: left;
    width: 100%;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: $secondary;
}

textarea:-moz-placeholder,
input:-moz-placeholder {
    color: $secondary;
}

textarea:-ms-placeholder,
input:-ms-placeholder {
    color: $secondary;
}

textarea:-o-placeholder,
input:-o-placeholder {
    color: $secondary;
}

#formRegistration {
    .containerElementForm {
        margin-top: 20px;
        text-align-last: left;
    }

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
    }

    input[type=checkbox] + label {
        position: relative;
        padding-left: 30px;
        font-size: 11pt;
        font-style:italic;
    }

    input[type=checkbox] + label:before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-color: white;
        border: 1px solid $tertiary;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    input[type=checkbox]:checked + label:after {
        content: "";
        background-image: url(../src/img/success_color.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: block;
        width: 13px;
        height: 13px;
        position: absolute;
        top: 3px;
        left: 3px;
    }
}

#formLogin {
    .btn-underline {
        margin-top: 0px;
        position: relative;
        top: -10px;
        padding-right: 20px;

        &:before {
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url(../src/img/arrow_right_primary.svg);
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 5px;
            right: 0px;
        }
    }
}
