.validation {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 140px;

    max-width: 140px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    position: relative;

    .status {
        position: absolute;
        bottom: -20px;
    }
}

.validationBarCode {
    background-image:url(../src/img/barcode_secondary.svg);

    .status {
        right: -20px;
    }
}

.validationTicket {
    background-image:url(../src/img/ticket_secondary.svg);

    .status {
        right: 0px;
    }
}
