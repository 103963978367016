.bcr {
    .viewport {
        position: relative;
        display: inline-block;
        width: 100%;

        > * {
            width: 100%;
        }

        canvas {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.barcode {
    //background-color: $secondary;
    //padding: 15px 30px;
    margin: 20px 0;

    p {
        color: white;
    }
}

// MSI Biocure = barcode of template "0000-000".
.msi-biocure {
    display: flex;
    align-items: center;
    justify-content: center;

    input, span {
        margin: 0 5px;
        padding: 10px 0;
        text-align: center !important;
        font-family: monospace;
        width: 30px !important;
    }

    input {
        border: 1px solid $secondary;
    }

    span {
        color: white;
        width: 10px !important;
    }
}