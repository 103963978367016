.backgroundPopup {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
}

.popup {
    position: fixed;
    z-index: 1000;
    background: rgba(256,256,256,0.95);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: table;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 680px;
    max-height: 680px;

    &.hide {
        display: none;
    }

    .content {
        display: table-cell;
        vertical-align: middle;
    }
}
