.btn {
    display: inline-block;
    padding: 15px 20px;
    font-weight: $fontWeightHouschkaRoundedDemiBold;
    border-radius: 8px;
    margin: 20px 0px;
    min-width: 160px;
    font-size: 14pt;
}

.btn-tertiary-text {
    color: darken( $tertiary, 20% ) !important;
}

.btn + .btn {
    margin-top: 0px;
}

.btn-plain {
    background-color: $primary;
    color: white;
    border: 1px solid $primary;

    &:not(.btn-disabled):not(:disabled):hover {
        background-color: lighten( $primary, 3% );
        cursor: pointer;
        @include transition(0.2s);
    }
}

.btn-inverse {
    background-color: white;
    color: $secondary;
    border: 1px solid $secondary;
}


.btn-arrow-right-primary, .btn-arrow-right-white, .btn-arrow-bottom-secondary, .btn-disabled {
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px;
    padding-right: 40px;

    &:disabled,  &.btn-disabled {
        background-image: url(../src/img/arrow_right_disabled.svg);
    }
}

.btn-arrow-right-primary {
    background-image: url(../src/img/arrow_right_primary.svg);
}

.btn-arrow-right-white {
    background-image: url(../src/img/arrow_right_white.svg);
}

.btn-arrow-bottom-secondary {
    background-image: url(../src/img/arrow_bottom_secondary.svg);
}

.btn-underline {
    text-decoration: none;
    display: inline-block;
    font-size: 14pt;

    &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background: $secondary;
    }

    &:hover {
        color: $primary;
        cursor: pointer;
        @include transition(0.2s);

        &:after {
            background: $primary;
            @include transition(0.2s);
        }
    }
}

.btn-google, .btn-facebook {
    padding-left: 40px;
    position: relative;

    .icon {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.btn-google {
    background-color: #DD514B;
    border: 1px solid #DD514B;

    .icon {
        background-image: url(../src/img/google_plus.svg);
    }

    &:hover {
        background-color: lighten( #DD514B, 3% ) !important;
        cursor: pointer;
        @include transition(0.2s);
    }
}

.btn-facebook {
    background-color: #4C6DB3;
    border: 1px solid #4C6DB3;

    .icon {
        background-image: url(../src/img/facebook.svg);
    }

    &:hover {
        background-color: lighten(#4C6DB3, 3% ) !important;
        cursor: pointer;
        @include transition(0.2s);
    }
}

.btn-add-product {
    position: relative;
    padding-right: 60px;

    &:after {
        content:"";
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 7px;
        right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../src/img/add_product.svg);
    }
}

.btn-disabled, .btn:disabled {
    background-color: $tertiary;
    border:1px solid $tertiary;
    color: $background;
}
