.containerCouponImage {
    background: white;
    border-radius: 10px;
    height: 200px;
    margin-bottom: 10px;
}

.couponChoices {
    font-size: 0px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.couponChoice {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);

    label {
        flex-grow: 1;
        display: block;
        padding: 30px 20px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid $tertiary;

        .status {
            display: none;
        }

        .productTitle {
            color: #999999;
            font-size: 14pt;
        }

        .imgProduct {
            height: 100px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../src/img/product.png);
            margin-bottom: 10px;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        left: 0px;
        bottom:0px;

        &:checked + label {
            border: 3px solid $secondary;

            .status {
                display: block;
                position: absolute;
                background-color: #fff;
                top: -11px;
                right: -11px;
            }
        }
    }
}