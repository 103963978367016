$primary: #006AA1;
$secondary: #013463;
$tertiary: #D0D0D0;
$background: #F1F1F1;
$success: #36B491;
$validation: #F29C21;
$error: #DD514B;

$fontFamilyHouschkaRounded: "houschka-rounded",sans-serif;
$fontWeightHouschkaRoundedMedium : 500;
$fontWeightHouschkaRoundedDemiBold : 600;