.tabProfil {
    width: 100%;

    th, td {
        text-align: left;
        padding-bottom: 20px;
    }

    th {
        width: 30%;
        font-weight: $fontWeightHouschkaRoundedDemiBold;
        padding-right: 20px;
    }

    td {
        width: 70%;
    }
}
