.tu {
    margin: 30px 0 50px 0;

    &__add, &__wait, &__check {
        font-size: 50px;
        text-align: center;
        margin: 30px 0;
    }

    &__add {
        label {
            font-size: 0;
            display: block;
            padding: 80px 0;
            background-color: rgba(255,255,255,0.68);
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
    }

    &__preview {
        position: relative;
        display: none;
        max-width: 80%;
        margin: 60px auto;

        .status {
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: white;
            width: 50px;
            height: 50px;
            transform: translate(-50%, 40%);
        }

        .tu__trash {
            position: absolute;
            top: 0;
            right: 0; left: auto;
            background-color: white;
            width: 50px;
            height: 50px;
            transform: translate(50%, -50%);
        }
    }
}

.corners {
    position: relative;
    padding: 10px;
    
    .corner {
        position: absolute;
        width: 30px; height: 30px;

        &:nth-child(1) {
            transform: rotate(0);
            top: 0; left: 0;
        }
        &:nth-child(2) {
            transform: rotate(90deg);
            top: 0; right: 0;
        }
        &:nth-child(3) {
            transform: rotate(180deg);
            bottom: 0; right: 0;
        }
        &:nth-child(4) {
            transform: rotate(270deg);
            bottom: 0; left: 0;
        }
    }
}