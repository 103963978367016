@import "variables";


/* MIXIN */

@mixin transition($time) {
    -webkit-transition: all $time;
    -moz-transition: all $time;
    -ms-transition: all $time;
    -o-transition: all $time;
    transition: all $time;
}


/* GENERAL */

html, body, * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    background-image: radial-gradient(#cce5f1, #4199da);
    color: $secondary;
    width: 100%;
    overflow-x: hidden;

    .dark-bg #content {
        background: radial-gradient(#006AA1, #013463);
    }
}

.vague {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    text-align: center;
    opacity: 0.3;
    z-index: 1;
}

img {
    max-width: 100%;
    height: auto;
}


#content {
    position: relative;
    z-index: 10;
    min-height: calc(100% - 60px);
}

.content {
    padding: 0px 20px 40px;
    max-width: 600px;
    margin: auto;
}

div, p, li, a, th, td, label, h1, h2, h3, span, option, input {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: $fontFamilyHouschkaRounded;
}

div, p, li, a, th, td, label, span, input {
    font-size: 12pt;
    font-weight: $fontWeightHouschkaRoundedMedium;
    text-align: center;
    line-height: 1.3;
}

a {
    text-decoration: none;
    color: inherit;
}

strong {
    font-weight: $fontWeightHouschkaRoundedDemiBold;
}

h1 {
    font-size: 22pt;
    font-weight: $fontWeightHouschkaRoundedDemiBold;
    text-align: center;
}

.bigText {
    font-size: 16pt;
    text-align: center;

    a {
       font-size: 16pt;
    }
}

.moyenText {
    font-size: 15pt;
    text-align: center;

    a {
       font-size: 15pt;
       text-decoration: underline;
    }
}

.indication {
    color: darken( $tertiary, 20% );
    margin-top: 5px;

    p {
        color: darken( $tertiary, 20% );

        &:not(:first-child){
            margin-top: 20px;
        }
    }
}

.happyMessage,
.veryHappyMessage,
.welcomeMessage {
    position: relative;
    margin: 60px 0;

    &:before {
        position: absolute;
        width: 100%;
        left: -15px;
    }

    &:after {
        position: absolute;
        width: 100%;
        left: -15px;
    }
}

.happyMessage {
    &:before {
        content:url(../src/img/happyMessageBefore.png);
        top: -40px;
    }

    &:after {
        content:url(../src/img/happyMessageAfter.png);
        bottom: -55px;
    }
}

.veryHappyMessage {
    &:before {
        content:url(../src/img/veryHappyMessageBefore.png);
        top: -45px;
    }

    &:after {
        content:url(../src/img/veryHappyMessageAfter.png);
        bottom: -60px;
    }
}

.welcomeMessage {
    margin: 40px auto;
    max-width: 360px;
    
    &:before {
        content:url(../src/img/welcomeMessageBefore.png);
        top: -25px;
    }

    &:after {
        content:url(../src/img/welcomeMessageAfter.png);
        bottom: -40px;
    }
}

.message {
    margin: 40px 0px 30px;
}

.instruction {
    background-color: $primary;

    > div {
        max-width: 600px;
        margin: auto;

        color: white;
        padding: 18px 10px 18px 60px;
        text-align: left;
        position: relative;
        font-size: 12pt;
    }

    .icon {
        position: absolute;
        top: calc(50% - 15px);
        left: 12px;
        display: inline-block;
        width: 30px;
        height: 30px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .iconBarCode {
       content:url(../src/img/barcode_white.svg);
    }

    .iconTicket {
       content:url(../src/img/ticket_white.svg);
    }

    &__img {
        position: absolute;
        top: -40px; right: -60px;
    }
}

.instruction-2 {
    background-color: #fff;
    padding: 10px 80px 10px 15px;
    border-radius: 8px;
    text-align: left;
    position: relative;
    margin: 30px 0;
    width: calc(100% - 30px);
}

.uppercase {
    text-transform: uppercase;
}

.w-100 {
    width: 100%;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.underline {
    text-decoration: underline;
}

.underline.bigText {
    font-size: 16pt;
}

div.errors {
    margin-bottom: 20px;

    .alert {
        color: red;

        &:not(:last-child){
            margin-bottom: 5px;
        }
    }
}

.backTo {
    position: absolute;
    top: -20px;
    left: 20px;
    padding-left: 20px;

    &:before {
        content: '';
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../src/img/arrow_left_primary.svg);
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 2px;
        left: -2px;
    }
}

.circled {
    width: 1.2em;
    height: 1.2em;
    line-height: 1.2em;
    display: inline-block;
    border: 1px solid;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;

    &.full {
        background-color: $secondary;
        border-color: $secondary;
        color: white;
    }
    
    &.medium {
        font-size: 2.5rem;
        margin: 0.2em;
    }

    &.big {
        font-size: 10rem;
        border-width: 3px;
    }
}

.stackblock {
    margin-top: 20px;
    margin-bottom: 50px;
}

.logo-light {
    max-width: 260px;
    margin: -60px 0 -70px 0;
}

.welcome {
    &__add {
        display: block;
        max-width: 164px;
        margin: 30px auto;
    }
}

.light {
    color: #fff;
}

@import "menu";

@import "cart";

@import "form";

@import "btn";

@import "status";

@import "validation";

@import "header";

@import "barcode-reader";

@import "coupon";

@import "popup";

@import "ticket-uploader";

@import "profil";

@import "gamme";

@import "product";