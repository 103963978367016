.listProducts {
    .products {
        font-size: 0px;
        border-bottom: 1px solid $tertiary;

        .product {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            position: relative;
            padding: 40px 20px 30px;
            border-top: 1px solid $tertiary;

            .productTitle {
                color: darken( $tertiary, 20% );
                font-size: 14pt;
                font-weight: $fontWeightHouschkaRoundedDemiBold;
            }

            .imgProduct {
                height: 100px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-bottom: 10px;
            }

            &:nth-child(even){
                border-left: 1px solid $tertiary;
            }
        }
    }
}

.imgSingleProduct {
    height: 160px;
    margin: 20px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
