header {

    nav {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1000;

        #menuToggle {
            display: block;
            position: relative;
            top: 18px;
            left: 20px;
            z-index: 1;
            -webkit-user-select: none;
            user-select: none;

            input {
                display: block;
                width: 40px;
                height: 40px;
                position: absolute;
                top: -5px;
                left: -5px;
                cursor: pointer;
                opacity: 0;
                z-index: 100000000000;
                -webkit-touch-callout: none;

                &:checked ~ #menu {
                  transform: none;
                }

                &:checked ~ span {
                    opacity: 1;
                    transform: rotate(45deg) translate(-2px, -1px);
                    background: $secondary;

                    &:nth-last-child(3) {
                        opacity: 0;
                        transform: rotate(0deg) scale(0.2, 0.2);
                    }

                    &:nth-last-child(2) {
                        transform: rotate(-45deg) translate(0, -1px);
                    }
                }
            }

            span {
                display: block;
                width: 32px;
                height: 2px;
                margin-bottom: 7px;
                position: relative;
                background: white;
                border-radius: 3px;
                z-index: 1000000000;
                transform-origin: 4px 0px;
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;

                &:first-child {
                    transform-origin: 0% 0%;
                }

                &:nth-last-child(2) {
                    transform-origin: 0% 100%;
                }
            }

            #user, #menuConnexion {
                border-bottom: 1px solid $tertiary;
                margin-bottom: 40px;
                position: relative;
                font-size: 14pt;
                color: $secondary;
                text-align: left;
                padding: 0px;
                padding-bottom: 20px;
                font-weight: $fontWeightHouschkaRoundedDemiBold;
                display: block;

                .iconArrow {
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url(../src/img/next.svg);
                    position: absolute;
                    right: 0px;
                }
            }

            #user {
                .iconUser {
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url(../src/img/icon_user.svg);
                    margin-right: 15px;
                }

                .iconArrow {
                    top: 8px;
                }
            }

            #menuConnexion {
                .iconArrow {
                    top: 0px;
                }
            }

            @keyframes menu {
                from {opacity: 0;}
                to {opacity: 1;}
            }

            #menu {
                max-width: 500px;
                position: fixed;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                margin: 0px 0 0 0px;
                padding: 80px 30px 60px;
                background: white;
                list-style-type: none;
                -webkit-font-smoothing: antialiased;
                transform-origin: 0% 0%;
                opacity: 0;
                transform: translate(-100%, 0);
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

                animation-name: menu;
                animation-delay: 1s;
                animation-fill-mode: forwards;
                animation-duration: 0.1s;

                .menu  {
                    list-style: none;
                    padding: 0px 0px 0px 40px;

                    li {
                        margin-bottom: 40px;

                        a {
                            font-size: 14pt;
                            text-align: left;
                            color: darken( $tertiary, 20% );
                            display: block;
                            text-decoration: none;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            #languages {
                margin-top: 80px;

                ul {
                    list-style: none;

                    li {
                        display: inline-block;
                        margin: 0px 40px;

                        a {
                            font-size: 14pt;
                            color: darken( $tertiary, 20% );
                        }

                    }

                    .active a {
                        color: $secondary;
                        position: relative;

                        &:before {
                            content: url(../src/img/arrow_right_primary.svg);
                            position: absolute;
                            top: 3px;
                            left: -27px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}
