.carts {
    .cart {

        margin-top: 40px;

        .titleCart {
            display: none;
            padding: 18px 60px 20px;
            position: relative;
            border-radius: 10px 10px 10px 10px;
            background-color: white;
            border: 1px solid $tertiary;
            text-align: left;
            text-transform: uppercase;
            font-weight: $fontWeightHouschkaRoundedDemiBold;
            cursor: pointer;

            .status {
                position: absolute;
                top: 12px;
                left: 15px;
            }

            .iconToggle {
                position: absolute;
                top: 18px;
                right: 15px;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url(../src/img/toggle.svg);
            }

            &.open {
                border-radius: 10px 10px 0px 0px;

                 .iconToggle {
                    transform: rotate(180deg);
                }
            }
        }

        &.completeCart {
            .titleCart {
                display: block;
            }

            .products {
                border-radius: 0px 0px 10px 10px;
                border-top: 0px;
                display: none;

                .product {
                    padding: 15px;

                    &.empty .product__action {
                        display: none;
                    }
                }
            }
        }

        &.empty {
            .products {
                .product {
                    padding: 15px;
                }
            }
        }

        .products {
            border-radius: 10px;
            background-color: white;
            border: 1px solid $tertiary;

            .product {
                display: flex;
                align-items: center;
                padding: 15px 0 15px 15px;
                position: relative;
                color: darken( $tertiary, 20% );
                font-weight: $fontWeightHouschkaRoundedDemiBold;

                .infosProduct {
                    flex-grow: 1;
                    padding: 0 15px;

                    div {
                        text-align: left;
                        color: darken( $tertiary, 20% );

                        &:nth-child(1){
                            font-weight: $fontWeightHouschkaRoundedDemiBold;
                        }

                        &:nth-child(2){
                            font-size: 10pt;
                        }
                    }
                }

                .status {
                    flex-shrink: 0;
                }

                .imgProduct {
                    flex-shrink: 0;
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url(../src/img/add_product.svg);
                }

                &__action {
                    width: 38px;
                    height: 38px;
                    padding: 10px;
                    flex-shrink: 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $tertiary;
                }
            }
        }

        .btn-disabled {
            margin-top:10px;
        }
    }
}
