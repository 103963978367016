header {
    height: 60px;
    background: radial-gradient($primary, $secondary);
    text-align: center;
    position: relative;
    z-index: 100;

    .logo {
        display: inline-block;
        margin-top: 10px;

        img {
            width: 130px;
        }
    }

    div.step {
        color: white;
        padding: 17px 30px;
        font-weight: $fontWeightHouschkaRoundedDemiBold;
        font-size: 20px;
    }
}
