.producto {
    background-color: #fff;
    padding: 20px 15px 40px 15px;
    border-radius: 8px;
    position: relative;
    margin: 30px 0;

    &__img {
        width: 120px;
    }

    .status {
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: white;
        width: 50px;
        height: 50px;
        transform: translate(-50%, 40%);
    }
}

#matching-product {
    display: none;
}